<template>
	<div>
		<div class="mp-bg pb-5">
			<div class="container pt-4 pb-4">
				<div class="row">
					<div class="col-12 text-left pt-5">
						<h2>
							{{ $t('message.pre_reservation_page.title1') }}
						</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-reservation pt-3 pb-5">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h3 class="pt-4 pb-3">
							{{ $t('message.pre_reservation_page.title2') }}
						</h3>
						<div class="row">
							<div class="col-12 col-md-4 mb-5">
								<div class="card">
									<h4 class="tag">
                    <span>
                      {{
							$t('message.pack_name', {
								pack : $t(
									`message.pack[0].${selectedPackData.pack_name}`
								),
							})
						}}
                    </span>
									</h4>
									<p class="car-name">
										{{ selectedPackData.car_name }}
									</p>
									<!--{{$t('message.similar')}}-->
									<p class="car-price">
                    <span>{{ $t('message.pack_since') }} </span
					>{{ selectedPackData.price }}€<span class="dia"
									>/{{ $t('message.pack_day') }}</span
									>
									</p>
									<p>
										<strong>{{ $t('message.form.pickup') }}: </strong>
										{{ form.pickup_location }} -
										{{ form.pickup_date }}
									</p>
									<p>
										<strong>{{ $t('message.form.delivery') }}: </strong>
										{{ setDeliveryLocation() }}
										-
										{{ form.delivery_date }}
									</p>
									<img class="card-img-top mt-3" :src="setImg()" alt=""/>
									<div class="divider"></div>
									<h5>{{ $t('message.form.value_title') }}</h5>

									<div class="rent-totals">
										<div class="subtotal">
											<div class="d-flex justify-content-between header">
												<div>{{ $t('message.form.car') }}</div>
												<div>{{ $t('message.form.subtotal') }}</div>
											</div>
											<div class="d-flex justify-content-between">
												<div>
													{{ rent_days }} {{ $t('message.form.day') }}(s)
												</div>
												<div>
													{{ (rent_days * selectedPackData.price).toFixed(2) }}€
												</div>
											</div>

											<div class="d-flex justify-content-between">
												<div>
													<strong>{{ $t('message.form.included') }}</strong>
												</div>
											</div>
											<div class="d-flex justify-content-between">
												<div style="margin-top: -10px; padding-left: 20px">
													<ul>
														<li>{{ $t('message.form.road_tax') }}</li>
														<li>{{ $t('message.form.cdw') }}</li>
													</ul>
												</div>
											</div>
										</div>

										<div
											class="subtotal"
											v-if="
                        form.age === 'Yes' ||
                        tax ||
                        form.extra_driver != 'No' ||
                        form.extra_pickup === true ||
                        form.extra_delivery === true ||
                        form.border === 'Yes'
                      "
										>
											<div class="d-flex justify-content-between header">
												<div>{{ $t('message.form.taxes') }}</div>
												<div>{{ $t('message.form.subtotal') }}</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="form.age === 'Yes'"
											>
												<div>{{ $t('message.form.age_minor') }}</div>
												<div>{{ calculateAgePrice() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="form.extra_driver != 'No'"
											>
												<div>{{ $t('message.form.extra_driver') }}</div>
												<div>{{ calculateExtraDriver() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="form.border === 'Yes'"
											>
												<div>
													{{ $t('message.form.border') }}
												</div>
												<div>{{ calculateBorderPrice() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="
                          form.pickup_location === 'Aeroporto de Lisboa/Porto'
                        "
											>
												<div>
													{{ $t('message.airport_pickup') }}
												</div>
												<div>{{ calculateExtraLocationPickup() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="
                          form.delivery_location === 'Aeroporto de Lisboa/Porto'
                        "
											>
												<div>
													{{ $t('message.airport_delivery') }}
												</div>
												<div>{{ calculateExtraLocationDelivery() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="form.extra_delivery === true"
											>
												<div>
													{{ $t('message.form.extra_delivery') }}
												</div>
												<div>{{ calculateExtraDelivery() }}€</div>
											</div>
											<div
												class="d-flex justify-content-between"
												v-if="form.extra_pickup === true"
											>
												<div>
													{{ $t('message.form.extra_pickup') }}
												</div>
												<div>{{ calculateExtraPickUp() }}€</div>
											</div>
										</div>
										<div
											class="subtotal"
											v-if="
                        form.via_verde === true ||
                        form.baby_chair === true ||
                        form.scdw === true
                      "
										>
											<div class="d-flex justify-content-between header">
												<div>Extras</div>
												<div>{{ $t('message.form.subtotal') }}</div>
											</div>
											<div
												class="d-flex justify-content-between"
												v-if="form.via_verde === true"
											>
												<div>
													1 x {{ $t('message.form.viaverde') }}
													{{ $t('message.form.to') }} {{ rent_days }}
													{{ $t('message.form.day') }}(s)
												</div>
												<div>{{ calculateViaVerde() }}€</div>
											</div>
											<div
												class="d-flex justify-content-between"
												v-if="form.scdw === true"
											>
												<div>
													1 x {{ $t('message.form.scdw') }}
													{{ $t('message.form.to') }} {{ rent_days }}
													{{ $t('message.form.day') }}(s)
												</div>
												<div>{{ calculateSCDW() }}€</div>
											</div>

											<div
												class="d-flex justify-content-between"
												v-if="form.baby_chair === true"
											>
												<div>
													1 x {{ $t('message.form.baby_chair') }}
													{{ $t('message.form.to') }} {{ rent_days }}
													{{ $t('message.form.day') }}(s)
												</div>
												<div>{{ calculateBabyChair() }}€</div>
											</div>
										</div>

										<div class="d-flex justify-content-between total">
											<div>{{ $t('message.form.total_preview') }}</div>
											<div>* {{ total }}€</div>
										</div>

										<div class="tax-msg">* {{ $t('message.tax') }}</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-8">
								<div class="card">
									<h4>{{ $t('message.form.receipt') }}</h4>

									<div
										class="alert alert-danger mt-3"
										role="alert"
										v-if="$v.form.name.$error && errors.length > 0"
									>
										<div v-for="error in errors">
											{{ error }}
										</div>
									</div>

									<div
										class="alert alert-danger mt-3"
										role="alert"
										v-if="$v.form.name.$error && errors.length < 1"
									>
										{{ $t('message.form.invalid_form') }}
									</div>

									<form action="" class="mt-3" @submit.prevent="submit">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label
														for=""
														:class="{ hasError: $v.form.name.$error }"
													>{{ $t('message.form.name') }}
														<span style="color: red">*</span></label
													>
													<input
														class="form-control"
														type="text"
														name="name"
														:class="{ hasError: $v.form.name.$error }"
														v-model="form.name"
													/>
													<div class="error" v-if="!$v.form.name.required">
														{{ $t('message.form.required_field') }}
													</div>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="">{{
															$t('message.form.company_name')
														}}</label>
													<input
														class="form-control"
														type="text"
														name="company_name"
														v-model="form.company_name"
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label
														for=""
														:class="{ hasError: $v.form.email.$error }"
													>{{
															$t('message.form.email')
														}}<span style="color: red">*</span></label
													>
													<input
														class="form-control"
														type="text"
														name="email"
														v-model="form.email"
														:class="{ hasError: $v.form.email.$error }"
													/>
													<div class="error" v-if="!$v.form.email.required">
														{{ $t('message.form.required_field') }}
													</div>
													<div class="error" v-if="!$v.form.phone.email">
														{{ $t('message.form.email_format') }}
													</div>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label
														for=""
														:class="{ hasError: $v.form.phone.$error }"
													>{{ $t('message.form.phone') }}
														<span style="color: red">*</span></label
													>
													<input
														class="form-control"
														type="text"
														name="phone"
														v-model="form.phone"
														:class="{ hasError: $v.form.phone.$error }"
													/>
													<div class="error" v-if="!$v.form.phone.required">
														{{ $t('message.form.required_field') }}
													</div>
													<div class="error" v-if="!$v.form.phone.phone">
														{{ $t('message.form.phone_format') }}
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<label for="" :class="{ hasError: $v.form.age.$error }"
												>{{ $t('message.form.age') }}
													<span style="color: red">*</span></label
												>
												<p class="pb-2">
													{{ $t('message.form.age_question') }}
												</p>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="age"
														id="inlineRadio1"
														value="Yes"
														v-model="form.age"
													/>
													<label class="form-check-label" for="inlineRadio1">{{
															$t('message.yes')
														}}</label>
												</div>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="age"
														id="inlineRadio2"
														value="No"
														v-model="form.age"
													/>
													<label class="form-check-label" for="inlineRadio2">{{
															$t('message.no')
														}}</label>
												</div>
												<div class="error" v-if="!$v.form.age.required">
													{{ $t('message.form.required') }}
												</div>
											</div>
										</div>
										<div class="row mt-4">
											<div class="col-12">
												<label for=""
												>{{ $t('message.form.extra_driver') }}
													<span style="color: red">*</span></label
												>
												<p class="pb-2">
													{{ $t('message.form.extra_driver_label') }}
												</p>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="extra_driver"
														id="inlineRadio3"
														value="Yes"
														v-model="form.extra_driver"
														@change="calculateExtraDriver"
													/>
													<label class="form-check-label" for="inlineRadio3">{{
															$t('message.yes')
														}}</label>
												</div>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="extra_driver"
														id="inlineRadio4"
														value="No"
														v-model="form.extra_driver"
													/>
													<label class="form-check-label" for="inlineRadio4">{{
															$t('message.no')
														}}</label>
												</div>
												<div
													class="error"
													v-if="!$v.form.extra_driver.required"
												>
													{{ $t('message.form.required') }}
												</div>
											</div>
										</div>

										<div class="row mt-4">
											<div class="col-12">
												<label for=""
												>{{ $t('message.form.border') }}
													<span style="color: red">*</span></label
												>
												<p class="pb-2">
													{{ $t('message.form.border_label') }}
												</p>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="border"
														id="inlineRadio5"
														value="Yes"
														v-model="form.border"
													/>
													<label class="form-check-label" for="inlineRadio5">{{
															$t('message.yes')
														}}</label>
												</div>
												<div class="form-check form-check-inline age">
													<input
														class="form-check-input"
														type="radio"
														name="border"
														id="inlineRadio6"
														value="No"
														v-model="form.border"
													/>
													<label class="form-check-label" for="inlineRadio6">{{
															$t('message.no')
														}}</label>
												</div>
												<div class="error" v-if="!$v.form.border.required">
													{{ $t('message.form.required') }}
												</div>
											</div>
										</div>

										<div class="divider"></div>
										<h5 class="mt-4">Taxas:</h5>
										<div class="extras pt-2">
											<div class="d-flex justify-content-between pb-2">
												<div>
													<h5 v-b-toggle.collapse-3>
														{{ $t('message.form.extra_delivery') }}
														<i class="pl-2 fas fa-chevron-down"></i>
													</h5>
													<b-collapse id="collapse-3">
														<b-card>{{
																$t('message.form.extra_delivery_content')
															}}
														</b-card>
													</b-collapse>
												</div>
												<div>
													<b-button
														v-if="validationButton"
														:pressed.sync="form.extra_delivery"
														variant="primary"
														v-model="form.extra_delivery"
														id="_delivery-btn"
													>
														{{
															form.extra_delivery === true
																? $t('message.form.remove')
																: $t('message.form.add')
														}}
													</b-button>
												</div>
											</div>
											<div class="d-flex justify-content-between pb-2">
												<div>
													<h5 v-b-toggle.collapse-4>
														{{ $t('message.form.extra_pickup') }}
														<i class="pl-2 fas fa-chevron-down"></i>
													</h5>
													<b-collapse id="collapse-4">
														<b-card>{{
																$t('message.form.extra_pickup_content')
															}}
														</b-card>
													</b-collapse>
												</div>
												<div>
													<b-button
														v-if="validationButton2"
														:pressed.sync="form.extra_pickup"
														variant="primary"
														v-model="form.extra_pickup"
														id="_pickup-btn"
													>
														{{
															form.extra_pickup === true
																? $t('message.form.remove')
																: $t('message.form.add')
														}}
													</b-button>
												</div>
											</div>
										</div>
										<div class="divider"></div>
										<h5 class="mt-4">Extras:</h5>

										<div class="extras pt-2">
											<div class="d-flex justify-content-between pb-2">
												<div>
													<h5 v-b-toggle.collapse-1>
														{{ $t('message.form.viaverde') }}
														<i class="pl-2 fas fa-chevron-down"></i>
													</h5>
													<b-collapse id="collapse-1">
														<b-card>{{
																$t('message.form.viaverde_content')
															}}
														</b-card>
													</b-collapse>
												</div>
												<div>
													<b-button
														:pressed.sync="form.via_verde"
														variant="primary"
														v-model="form.via_verde"
													>
														{{
															form.via_verde === true
																? $t('message.form.remove')
																: $t('message.form.add')
														}}
													</b-button>
												</div>
											</div>
											<div class="d-flex justify-content-between pb-2">
												<div>
													<h5 v-b-toggle.collapse-5>
														{{ $t('message.form.scdw') }}
														<i class="pl-2 fas fa-chevron-down"></i>
													</h5>
													<b-collapse id="collapse-5">
														<b-card>{{
																$t('message.form.scdw_content')
															}}
														</b-card>
													</b-collapse>
												</div>
												<div>
													<b-button
														:pressed.sync="form.scdw"
														variant="primary"
														v-model="form.scdw"
														@click="setCDW()"
														:disabled="form.scdw_disabled"
													>
														{{
															form.scdw === true
																? $t('message.form.remove')
																: $t('message.form.add')
														}}
													</b-button>
												</div>
											</div>

											<!-- <div class="d-flex justify-content-between pb-2">
																	  <div>
																		  <h5 v-b-toggle.collapse-2>
																			  {{ $t('message.form.baby_chair') }} <i
																			  class="pl-2 fas fa-chevron-down"></i>
																		  </h5>
																		  <b-collapse id="collapse-2">
																			  <b-card>{{ $t('message.form.baby_chair_content') }}</b-card>
																		  </b-collapse>
																	  </div>
																	  <div>
																		  <b-button :pressed.sync="form.baby_chair" variant="primary"
																					v-model="form.baby_chair">
																			  {{
																				  form.baby_chair === true ?
																					  $t('message.form.remove') : $t('message.form.add')
																			  }}
																		  </b-button>
																	  </div>
																  </div>
																  <div class="d-flex justify-content-between">
																	  <div>
																		  <h5 v-b-toggle.collapse-3>
																			  {{ $t('message.form.border') }}<i
																			  class="pl-2 fas fa-chevron-down"></i>
																		  </h5>
																		  <b-collapse id="collapse-3">
																			  <b-card>{{ $t('message.form.border_content') }}</b-card>
																		  </b-collapse>
																	  </div>
																	  <div>
																		  <b-button :pressed.sync="form.border" variant="primary"
																					v-model="form.border">
																			  {{
																				  form.border === true ?
																					  $t('message.form.remove') : $t('message.form.add')
																			  }}
																		  </b-button>
																	  </div>
																  </div> -->
										</div>

										<div class="divider"></div>

										<h5 class="mt-4">
											{{ $t('message.form.payment') }}
										</h5>

										<div class="payment pt-2 pb-3">
											<div class="boxed">
												<p class="title">
													{{ $t('message.form.payment_act') }}
												</p>
												<p class="body">
													{{ $t('message.form.payment_info_1') }}
												</p>
											</div>

											<p class="pt-3">
												{{ $t('message.form.privacy') }}
												<a
													style="text-decoration: underline"
													v-bind:href="'/' + lang + '/politica-privacidade'"
													target="_blank"
												>{{ $t('message.form.policies') }}</a
												>
											</p>

											<div class="form-check pt-3 pb-3">
												<input
													class="form-check-input"
													type="checkbox"
													value="true"
													name="terms"
													id="defaultCheck1"
													v-model="form.terms"
												/>
												<label
													class="form-check-label"
													for="defaultCheck1"
													:class="{ hasError: $v.form.terms.$error }"
												>
													{{ $t('message.form.rgpd') }}
													<a
														style="text-decoration: underline"
														v-bind:href="'/' + lang + '/condicoes-aluguer'"
														target="_blank"
													>{{ $t('message.form.terms') }}</a
													>
													<span style="color: red"> *</span>
												</label>
											</div>
										</div>
										<div class="text-center">
											<button class="btn btn-primary btn-wide">
												{{ $t('message.form.pre_reservation') }}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>


<script>
import axios                      from 'axios';
import moment                     from 'moment';
import {email, helpers, required} from 'vuelidate/lib/validators';
import json                       from '../../website-data.json';
import Location                   from '../components/Location';

export default {
	name       : 'ReservationPage',
	components : {
		Location,
	},
	data       : function () {
		let query = this.$route.query;
		return {
			json              : json,
			lang              : this.$route.params.lang,
			tax               : false,
			form              : {
				delivery_location :
					query.delivery_location !== ''
						? query.delivery_location
						: query.pickup_location,
				delivery_change   : query.delivery_change === 'false' ? false : true,
				pickup_location   : query.pickup_location,
				pickup_date       : query.pickup_date + ' ' + query.pickup_time,
				delivery_date     : query.delivery_date + ' ' + query.delivery_time,
				pack              : query.pack,
				name              : '',
				company_name      : '',
				phone             : '',
				email             : '',
				age               : 'No',
				extra_driver      : 'No',
				border            : 'No',
				via_verde         : false,
				road_tax          : true,
				cdw               : true,
				scdw              : false,
				scdw_disabled     : false,
				extra_delivery    : false,
				extra_pickup      : false,
				terms             : false,
			},
			selectedPackData  : this.getPackData(query.pack),
			rent_days         : this.getNumberOfDays(
				query.pickup_date,
				query.delivery_date,
				query.pickup_time,
				query.delivery_time
			),
			total             : 0,
			errors            : [],
			success           : false,
			loadSpinner       : false,
			validationButton  : true,
			validationButton2 : true,
		};
	},
	computed   : {
		rules() {
			const mustBeTrue = (value) => !helpers.req(value) || value === true;
			const phone      = helpers.regex('phone', /^[0-9]\d{8}$/);
			return {
				name              : { required },
				phone             : { required, phone },
				email             : { required, email },
				age               : { required },
				pickup_date       : { required },
				delivery_date     : { required },
				pickup_location   : { required },
				extra_driver      : { required },
				border            : { required },
				terms             : { required, mustBeTrue },
				delivery_location :
					this.form.delivery_change === true ? { required } : {},
			};
		},
		path() {
			return this.$store.getters.setLocale;
		},
	},
	validations() {
		return {
			form : this.rules,
		};
	},
	watch        : {
		// call the method again if the route changes
		$route     : 'asyncData',
		'form.age' : function (val) {
			if (val === 'Yes') {
				this.calculateSCDW();
				this.form.scdw          = true;
				this.form.scdw_disabled = true;
				this.form.cdw           = false;
			}
			if (val === 'No') {
				this.calculateSCDW();
				this.form.scdw          = false;
				this.form.scdw_disabled = false;
				this.form.cdw           = true;
			}
		},
	},
	created      : function () {
		this.$watch('form', this.calculateTotal, {
			deep : true,
		});
	},
	mounted      : function () {
		this.calculateTotal();
	},
	beforeCreate : function () {
		if (
			new Date(this.$route.query.pickup_date) >
			new Date(this.$route.query.delivery_date)
		) {
			vm.$swal.fire({
				text              :
					this.$route.params.lang === 'pt'
						? 'A data de entrega não pode ser inferior à data de recolha.'
						: 'Delivery date cannot be lower than pickup date.',
				type              : 'error',
				icon              : 'error',
				confirmButtonText : '',
				showConfirmButton : false,
				allowOutsideClick : false,
				timer             : 5000,
			});
			setTimeout(() => {
				this.$router
					.push({ path : `/${this.$route.params.lang}` })
					.catch((err) => {
					});
			}, 5000);
		}
	},
	methods      : {
		setDeliveryLocation() {
			return this.form.delivery_location === ''
				? this.form.pickup_location
				: this.form.delivery_location;
		},
		setCDW() {
			if (this.form.scdw) {
				this.form.cdw = false;
				return;
			}
			this.form.cdw = true;
		},
		submit(e) {
			e.preventDefault();
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				this.scrollToTop();
				return;
			}

			this.form.pack_name            = this.selectedPackData.pack_name;
			this.form.rent_days            = this.rent_days;
			this.form.total_price          = this.total;
			this.form.viaverde_price       = this.calculateViaVerde();
			this.form.cdw_price            = 0;
			this.form.scdw_price           = this.calculateSCDW();
			this.form.border_price         = this.calculateBorderPrice();
			this.form.extra_delivery_price = this.calculateExtraDelivery();
			this.form.extra_pickup_price   = this.calculateExtraPickUp();
			this.form.age_price            = this.calculateAgePrice();
			this.form.extra_driver_price   = this.calculateExtraDriver();
			this.form.lang                 = this.lang;

			let vm = this;

			axios
				.post(process.env.VUE_APP_RESERVATION_URL, vm.form, {
					headers : {
						'Content-Type'                : 'application/json',
						'Access-Control-Allow-Origin' : '*',
						Accept                        : 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.message === 'pre-reservation accepted') {
						vm.success     = true;
						vm.loadSpinner = false;
						vm.dispatchSubmitEvent();

						let text =
								vm.lang === 'pt'
									? 'Pedido enviado com sucesso! Irá receber em breve confirmação no seu email.'
									: 'Pre-reservation submitted. A confirmation email was sent.';
						vm.$swal.fire({
							text              : text,
							type              : 'success',
							icon              : 'success',
							confirmButtonText : '',
							showConfirmButton : false,
							allowOutsideClick : false,
							timer             : 5000,
						});

						vm.$gtm.trackEvent({
							event          : 'form_click',
							category       : 'lead_form',
							action         : 'alugar | Step 2',
							label          : vm.selectedPackData.car_name,
							value          : 0,
							noninteraction : false, // Optional
						});

						setTimeout(() => {
							vm.$router.push({ path : `/${vm.lang}` }).catch((err) => {
							});
						}, 5000);
					} else {
						vm.errors      = response.data.email;
						vm.loadSpinner = false;
						vm.disabled    = false;
					}
				})
				.catch(function (error) {
					vm.errors      = ['Error. Something happened.'];
					vm.loadSpinner = false;
					vm.disabled    = false;
				});
		},
		dispatchSubmitEvent() {
			if (this.$gtm.enabled()) {
				window.dataLayer?.push({
					event          : 'Lubrirent-PedidoSucesso', // Event type [default = 'interaction'] (Optional)
					category       : 'Lubrirent-PedidoSucesso',
					action         : 'Lubrirent-PedidoSucesso',
					label          : 'Lubrirent-PedidoSucesso',
					value          : 0,
					noninteraction : false, // Optional
				});
			}
		},
		calculateTotal() {
			let carTotal = this.selectedPackData.price * this.rent_days;
			let total    =
					carTotal +
					this.calculateAgePrice() +
					this.calculateViaVerde() +
					this.calculateSCDW() +
					this.calculateExtraDelivery() +
					this.calculateExtraPickUp() +
					this.calculateExtraDriver() +
					this.calculateExtraLocation() +
					this.calculateBorderPrice();
			this.total   = total.toFixed(2);
		},
		calculateViaVerde() {
			let viaVerdePrice = 1.5; // 20€ reserva
			let maxPrice      = 15;
			if (this.form !== undefined && this.form.via_verde) {
				let total = viaVerdePrice * this.rent_days;
				return total >= maxPrice ? maxPrice : total;
			} else {
				return 0;
			}
		},
		calculateBabyChair() {
			let babyChairPrice = 6; // 6€ dia, max 60€
			let maxPrice       = 60;
			if (this.form !== undefined && this.form.baby_chair) {
				let total = babyChairPrice * this.rent_days;
				return total >= maxPrice ? maxPrice : total;
			} else {
				return 0;
			}
		},
		calculateSCDW() {
			let scdwPrice = 20; //20€ dia
			if (
				this.form !== undefined &&
				((this.form.scdw && this.form.age === 'Yes') || this.form.scdw)
			) {
				let total = scdwPrice * this.rent_days;
				return total;
			} else {
				return 0;
			}
		},
		calculateExtraDelivery() {
			let deliveryPrice = 50; //valor fixo
			let temp          = this.$route.query.delivery_date;
			let day           = moment(temp, 'DD/MM/YYYY', true).format('dddd');
			if (day == 'Sunday' || day == 'Saturday') {
				this.form.extra_delivery = true;
				this.validationButton    = false;
				return deliveryPrice;
			} else {
				return this.form !== undefined && this.form.extra_delivery
					? deliveryPrice
					: 0;
			}
		},
		calculateExtraPickUp() {
			let pickUpPrice = 50; //valor fixo
			let temp        = this.$route.query.pickup_date;
			let day         = moment(temp, 'DD/MM/YYYY', true).format('dddd');
			if (day == 'Sunday' || day == 'Saturday') {
				this.form.extra_pickup = true;
				this.validationButton2 = false;
				return pickUpPrice;
			} else {
				return this.form !== undefined && this.form.extra_pickup
					? pickUpPrice
					: 0;
			}
		},
		calculateExtraLocation() {
			let price = 100;
			return (
				(this.form.delivery_location === 'Aeroporto de Lisboa/Porto'
					? price
					: 0) +
				(this.form.pickup_location === 'Aeroporto de Lisboa/Porto' ? price : 0)
			);
		},
		calculateExtraLocationDelivery() {
			let price = 100;
			return this.form.delivery_location === 'Aeroporto de Lisboa/Porto'
				? price
				: 0;
		},
		calculateExtraLocationPickup() {
			let price = 100;
			return this.form.pickup_location === 'Aeroporto de Lisboa/Porto'
				? price
				: 0;
		},
		calculateAgePrice() {
			let agePrice = 40; //fixo
			// let dayPrice = 20;
			if (this.form !== undefined && this.form.age === 'Yes') {
				return agePrice;
			}
			return 0;
		},
		calculateExtraDriver() {
			let extraDriverPrice = 30;
			let extraDriverCount = 1;
			if (this.form.extra_driver === 'Yes') {
				let total =
						extraDriverPrice * (extraDriverCount > 5 ? 5 : extraDriverCount);
				return parseInt(total);
			} else {
				return 0;
			}
		},
		calculateBorderPrice() {
			let borderPrice = 100; // fixed price
			return this.form !== undefined && this.form.border === 'Yes'
				? borderPrice
				: 0;
		},
		getNumberOfDays(date1, date2, time1, time2) {
			let dateDiff = this.datediff(
				this.parseDate(date1, time1),
				this.parseDate(date2, time2)
			);
			return Math.ceil(dateDiff);
		},
		getPackData(pack) {
			let val = json.packs.filter((e) => {
				return e.pack_route === pack;
			});

			return val[0];
		},
		parseDate(str, str2) {
			let dmy = str.split('/');
			let hm  = str2.split(':');
			return new Date(dmy[2], dmy[1] - 1, dmy[0], hm[0], hm[1]);
		},
		datediff(first, second) {
			// Take the difference between the dates and divide by milliseconds per day.
			// Round to nearest whole number to deal with DST.
			return (second - first) / (1000 * 60 * 60 * 24);
		},
		setImg() {
			let screen = window.innerWidth < 768 ? 'mobile' : 'desktop';
			return `https://vault13.rotauto.com/sites/Lubrirent/cars/${this.selectedPackData.img_src}`;
		},
		scrollToTop() {
			window.scroll({
				top      : 0,
				left     : 0,
				behavior : 'smooth',
			});
		},
	},
};
</script>
